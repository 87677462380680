import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import Logo from "../../vendor/logoSTATIONREV.jpg";
import ResetPasswordForm from "./ResetPasswordForm";
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword({ stage }) {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Récupération du mot de passe" />
        <img
          src={Logo}
          alt="Logo"
          width={450}
          height={160}
          style={{ margin: 16 }}
          onClick={() => {
            navigate("/");
          }}
        />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Recupération du mot de passe
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Entrez votre Email pour recupérer votre mot de passe
        </Typography>

        <ResetPasswordForm stageCode={stage} />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
