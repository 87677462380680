import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

//User Page
const Home = async(() => import("./pages/home"));
const Stations = async(() => import("./pages/userDashboard/Stations"));
const StationsDetails = async(() =>
  import("./pages/userDashboard/StationsDetails")
);
const Vehicules = async(() => import("./pages/userDashboard/Vehicules"));
const Users = async(() => import("./pages/userDashboard/Users"));

//Admin Pages
const OrgasAdmin = async(() =>
  import("./pages/adminDashboard/OrganisationsAdmin")
);
const StationsAdmin = async(() =>
  import("./pages/adminDashboard/StationsAdmin")
);
const StationsDetailsAdmin = async(() =>
  import("./pages/adminDashboard/StationsDetailsAdmin")
);
const UsersAdmin = async(() => import("./pages/adminDashboard/UsersAdmin"));
const VehiculesAdmin = async(() =>
  import("./pages/adminDashboard/VehiculesAdmin")
);

// Icon components
//const Profile = async(() => import("./pages/pages/Profile"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "home",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "stations",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Stations />,
      },
      {
        path: ":id",
        element: <StationsDetails />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Users />,
      },
    ],
  },
  {
    path: "vehicules",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Vehicules />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword stage={1} />,
      },
      {
        path: "reset-password-code",
        element: <ResetPassword stage={2} />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "adminorganisations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrgasAdmin />,
      },
    ],
  },
  {
    path: "adminstations",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <StationsAdmin />,
      },
      {
        path: ":orga/:id",
        element: <StationsDetailsAdmin />,
      },
    ],
  },
  {
    path: "adminvehicules",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <VehiculesAdmin />,
      },
      {
        path: ":orga/:id",
        element: <VehiculesAdmin />,
      },
    ],
  },
  {
    path: "adminusers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UsersAdmin />,
      },
    ],
  },
];

export default routes;
