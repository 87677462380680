import { Users, Database, Eye, Download, Grid, Crosshair } from "react-feather";
// import "../../../src/.css";

const adminSection = [
  {
    href: "/home",
    icon: Grid,
    title: "Accueil",
  },
  {
    href: "/adminorganisations",
    icon: Database,
    title: "Organisations",
  },
  {
    href: "/adminstations",
    icon: Crosshair,
    title: "Stations",
    /*children: [
      {
        href: "/stations/home",
        title: "General",
      },
      {
        href: "/stations/parking",
        title: "Parking",
      },
      {
        href: "/stations/renting",
        title: "Location",
      },
    ],*/
  },
  {
    href: "/adminusers",
    icon: Users,
    title: "Users",
  },
  {
    href: "/adminvehicules",
    icon: Eye,
    title: "Véhicules",
  },
];

const navItems = [
  {
    title: "Super Admin",
    pages: adminSection,
    // className: "sidebar-title",
    style: {
      fontWeight: "bold",
    },
  },
];

export default navItems;
