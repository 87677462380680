import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Paper, Typography } from "@mui/material";

import Logo from "../../vendor/logoSTATIONREV.jpg";
import SignInComponent from "../../components/auth/SignIn";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        
        <Typography component="h1" variant="h4" align="center" gutterBottom>
        <img
          src={Logo}
          alt="Logo S REV"
          width={300}
          height={106}
          style={{ margin: 10 }}
        />
          Bienvenue sur le Manager REV
        </Typography>


        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
