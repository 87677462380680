
import { Users, Database, Eye, Download, Grid, Crosshair, Map, MapPin } from "react-feather";
// import "../../../src/.css";

const adminSection = [
  {
    href: "/home",
    icon: Grid,
    title: "Accueil",
  },
  {
    href: "/stations",
    icon: MapPin,
    title: "Station",
    /*children: [
      {
        href: "/stations/home",
        title: "General",
      },
      {
        href: "/stations/parking",
        title: "Parking",
      },
      {
        href: "/stations/renting",
        title: "Location",
      },
    ],*/
  },
  {
    href: "/vehicules",
    icon: Crosshair,
    title: "Vehicule",
  },
  {
    href: "/users",
    icon: Users,
    title: "Users",
  },
];

const navItems = [
  {
    title: "Administrateur",
    pages: adminSection,
    //className: "sidebar-title",
    style: {
      fontWeight: "bold",
    },
  },
];

export default navItems;
