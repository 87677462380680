import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../redux/slices/datasrev";
import { useEffect } from "react";
//import { User } from "react-feather";
import useAuth from "../../hooks/useAuth";

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarUserDropdown from "./NavbarUserDropdown";

function DisplayUser(auth) {
  if (auth.user.email) {
    return auth.user.email;
  } else {
    return "Loading Username...";
  }
}

function DisplayOrg() {
  const datasrev = useSelector((state) => state.datasrev);
  if (datasrev.user) {
    switch (datasrev.user.ad_orga) {
      case "*":
        return "Super Administrateur";
      case "#":
        return "Utilisateur";
      default:
        if (datasrev.orga) {
          return datasrev.orga.orga_name;
        } else {
          return "Chargement";
        }
    }
  } else {
    if (datasrev.orga) {
      return datasrev.orga.orga_name;
    } else {
      return "Chargement";
    }
  }
}

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  max-width: 100vw;
  overflow-x: hidden;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  //const datasrev = useSelector((state) => state.datasrev);
  const auth = useAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="flex">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <div style={{ color: "black", fontSize: "15px" }}>
                {DisplayUser(auth)}
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >
                {DisplayOrg()}
              </div>
            </Grid>
            <Grid item>
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);

// import React from "react";
// import styled from "@emotion/styled";
// import { withTheme } from "@emotion/react";
// //import { darken } from "polished";
// //import { Search as SearchIcon } from "react-feather";
// //import { useTranslation } from "react-i18next";

// import {
//   Grid,
//   AppBar as MuiAppBar,
//   IconButton as MuiIconButton,
//   Toolbar,
// } from "@mui/material";

// import { Menu as MenuIcon } from "@mui/icons-material";

// import NavbarUserDropdown from "./NavbarUserDropdown";

// const AppBar = styled(MuiAppBar)`
//   background: ${(props) => props.theme.header.background};
//   color: ${(props) => props.theme.header.color};
//   max-width: 100%;
// `;

// const IconButton = styled(MuiIconButton)`
//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// /*
// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${(props) => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;

//   &:hover {
//     background-color: ${(props) => darken(0.05, props.theme.header.background)};
//   }

//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;

//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)};
//     padding-right: ${(props) => props.theme.spacing(2.5)};
//     padding-bottom: ${(props) => props.theme.spacing(2.5)};
//     padding-left: ${(props) => props.theme.spacing(12)};
//     width: 160px;
//   }
// `;
// */

// const Navbar = ({ onDrawerToggle }) => {
//   //const { t } = useTranslation();

//   return (
//     <React.Fragment>
//       <AppBar position="sticky" elevation={0}>
//         <Toolbar>
//           <Grid container alignItems="center">
//             <Grid item sx={{ display: { xs: "block", md: "none" } }}>
//               <IconButton
//                 color="inherit"
//                 aria-label="Open drawer"
//                 onClick={onDrawerToggle}
//                 size="large"
//               >
//                 <MenuIcon />
//               </IconButton>
//             </Grid>
//             {/*
//             <Grid item>
//               <Search>
//                 <SearchIconWrapper>
//                   <SearchIcon />
//                 </SearchIconWrapper>
//                 <Input placeholder={t("Search")} />
//               </Search>
//             </Grid>
//             */}
//             <Grid item xs />
//             <Grid item>
//               <NavbarUserDropdown />
//             </Grid>
//           </Grid>
//         </Toolbar>
//       </AppBar>
//     </React.Fragment>
//   );
// };

// export default withTheme(Navbar);
