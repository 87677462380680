import { Grid, Users, MapPin, Crosshair } from "react-feather";

const pagesSection = [
  {
    href: "/home",
    icon: Grid,
    title: "Accueil",
  },/*
  {
    href: "/stations",
    icon: MapPin,
    title: "Accès",
  },
  {
    href: "/vehicules",
    icon: Crosshair,
    title: "Locations",
  }*/
];

const navItems = [
  {
    title: "Utilisateur",
    pages: pagesSection,
  },
];

export default navItems;
