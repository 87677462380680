import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { useState } from "react";
/*
              .matches(
                /^(?=.*[a-z])/,
                "Au moins un lettre miniscule"
              )
              .matches(
                /^(?=.*[A-Z])/,
                "Au moins une Lettre Majuscule"
              )
              .matches(/^(?=.*[0-9])/, "Au moins un chiffre")
              .matches(
                /^(?=.*[!@#$%^&*])/,
                "Au moins un caractère spécial parmis ! @ # $ % ^ & * "
              )
              */
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPasswordForm({ stageCode }) {
  const [stage, setStage] = useState(stageCode); // 1 = email stage, 2 = code stage
  const navigate = useNavigate();
  const { resetPassword, confirmPassword } = useAuth();

  return (
    <>
      {stage === 1 && (
        <div>
          <Formik
            initialValues={{
              email: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Doit être un Email valide")
                .max(255)
                .required("Email requis"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                resetPassword(values.email);
                setStage(2);
                //navigate("/auth/sign-in");
              } catch (error) {
                const message = error.message || "Erreur";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  autoComplete="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={3}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Reset du mot de passe
                </Button>
              </form>
            )}
          </Formik>
          <Button
            onClick={() => {
              setStage(2);
            }}
            fullWidth
            color="primary"
          >
            J'ai déja reçu mon code de vérification
          </Button>
        </div>
      )}

      {stage === 2 && (
        <Formik
          initialValues={{
            email: "",
            code: "",
            password: "",
            confirmedPassword: "",
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            code: Yup.string()
              .min(6, "Au moins 6 caractères")
              .required("Requis"),
            email: Yup.string()
              .email("Email invalide")
              .max(255)
              .required("Requis"),
            password: Yup.string()
              .min(8, "Au moins 8 caractères")
              .max(255)
              .matches(/^(?=.*[a-z])/, "Au moins un lettre miniscule")
              .matches(/^(?=.*[A-Z])/, "Au moins une Lettre Majuscule")
              .matches(/^(?=.*[0-9])/, "Au moins un chiffre")
              .matches(
                /^(?=.*[!@#$%^&*])/,
                "Au moins un caractère spécial parmis ! @ # $ % ^ & * "
              )
              .required("Requis"),
            confirmedPassword: Yup.string().when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: () =>
                Yup.string().oneOf(
                  [Yup.ref("password")],
                  "Les mot de passe doivent être identiques"
                ),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              confirmPassword(values.email, values.code, values.password);
              navigate("/auth/sign-in");
            } catch (error) {
              const message = error.message || "Erreur";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <TextField
                type="code"
                name="code"
                label="Code de vérification"
                value={values.code}
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="email"
                name="email"
                label="Email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                autoComplete="username"
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                name="password"
                label="Mot de passe"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                autoComplete="new-password"
                variant="outlined"
                my={2}
              />

              <TextField
                name="confirmedPassword"
                label="Confirmez le mot de passe"
                value={values.confirmedPassword}
                error={Boolean(
                  touched.confirmedPassword && errors.confirmedPassword
                )}
                fullWidth
                helperText={
                  touched.confirmedPassword && errors.confirmedPassword
                }
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                autoComplete="new-password"
                variant="outlined"
                my={2}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Enregistrer le nouveau mot de passe
              </Button>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}

export default ResetPasswordForm;
