import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { isValidToken } from "../../utils/jwt";

//Slice

const slice = createSlice({
  name: "adminsrev",
  initialState: {
    organisations: [],
    users: [],
    customers: [],
    stations: [],
    booking: [],
    access: [],
    vehicules: [],
    devicestation: [],
    routers: [],
    accessreaders: [],
    accessreadersdata: [],
    stationaccessuser: [],
    accessuserdetail: [],
    bookingdata: [],
  },
  reducers: {
    getOrgasAdmin: (state, action) => {
      state.organisations = action.payload;
    },
    getUsersAdmin: (state, action) => {
      state.users = action.payload;
    },
    getCustomersAdmin: (state, action) => {
      state.customers = action.payload;
    },
    getStationsAdmin: (state, action) => {
      state.stations = action.payload;
    },
    getBookingAdmin: (state, action) => {
      state.booking = action.payload;
    },
    getAccessAdmin: (state, action) => {
      state.access = action.payload;
    },
    getVehiculesAdmin: (state, action) => {
      state.vehicules = action.payload;
    },
    getDevicesStationAdmin: (state, action) => {
      state.devicestation = action.payload;
    },
    addOrga: (state, action) => {
      state.organisations = [...state.organisations, action.payload];
    },
    removeOrga: (state, action) => {
      state.organisations = state.organisations.filter(
        (item) => item.id_orga !== action.payload
      );
    },
    removeUser: (state, action) => {
      //console.log(action.payload);
      //console.log(typeof(state.users));
      state.users = state.users.filter(
        (item) => item.data_type !== action.payload
      );
    },
    updateStateUser: (state, action) => {
      state.users = state.users.map((item) => {
        if (item.data_type === action.payload.data_type) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    updateStateOrga: (state, action) => {
      state.organisations = state.organisations.map((item) => {
        if (item.id_orga === action.payload.id_orga) {
          return action.payload;
        } else {
          return item;
        }
      });
    },
    getRouter: (state, action) => {
      state.routers = action.payload;
    },
    getReader: (state, action) => {
      state.accessreaders = action.payload;
    },
    getReaderData: (state, action) => {
      state.accessreadersdata = action.payload;
    },
    getAccessUserData: (state, action) => {
      state.stationaccessuser = action.payload;
    },
    getUserDetails: (state, action) => {
      state.accessuserdetail = action.payload;
    },
    getBookingData: (state, action) => {
      state.bookingdata = action.payload;
    },
  },
});

export default slice.reducer;

const {
  getOrgasAdmin,
  getUsersAdmin,
  getCustomersAdmin,
  getStationsAdmin,
  getBookingAdmin,
  getAccessAdmin,
  getVehiculesAdmin,
  getDevicesStationAdmin,
  addOrga,
  removeOrga,
  removeUser,
  updateStateOrga,
  getRouter,
  getReader,
  getReaderData,
  getAccessUserData,
  getUserDetails,
  getBookingData,
  updateStateUser,
} = slice.actions;

function CheckConfigAxios() {
  //We check if token is loaded into Axios Config
  if (axiosInstance.defaults.headers.common.Authorization == null) {
    //console.log("NO TOKEN IN AXIOS CONFIG, NEED TO LOAD ONE !");
    let token = localStorage.getItem("accessToken");
    if (isValidToken(token)) {
      axiosInstance.defaults.headers.common.Authorization = token;
    } else {
      console.log("YOU HAVE TO RECONNECT");
    }
  } else {
    if (!isValidToken(axiosInstance.defaults.headers.common.Authorization)) {
      console.log("YOU HAVE TO RECONNECT");
    }
  }
}

export const fetchOrganisationsAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/organisation`)
      .then((response) => dispatch(getOrgasAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchUsersAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/customer`)
      .then((response) => dispatch(getUsersAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchCustomersAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/customer`)
      .then((response) => dispatch(getCustomersAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchStationsAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/station`)
      .then((response) => dispatch(getStationsAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchDevicesStationsAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/devicestation`)
      .then((response) => dispatch(getDevicesStationAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchBookingAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/booking`)
      .then((response) => dispatch(getBookingAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchAccessAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/access`)
      .then((response) => dispatch(getAccessAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchVehiculesAdmin = () => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/system/vehicule`)
      .then((response) => dispatch(getVehiculesAdmin(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const createOrga = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .post(`organisation`, data)
      .then((response) => dispatch(addOrga(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateOrga = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .put(`organisation/${data.id_orga}`, data)
      .then((response) => dispatch(updateStateOrga(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteOrga = (data) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .delete(`organisation/${data.id_orga}`)
      .then((response) => dispatch(removeOrga(data.id_orga)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchRouters = (id_station, lid_orga) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/organisation/${lid_orga}/station/${id_station}/router`)
      .then((response) => dispatch(getRouter(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchAccessReader = (id_station, lid_orga) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/organisation/${lid_orga}/station/${id_station}/access`)
      .then((response) => dispatch(getReader(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchAccessReaderData =
  (id_station, lid_orga, id_reader) => async (dispatch) => {
    CheckConfigAxios();
    //console.log(`/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`);
    try {
      axiosInstance
        .get(
          `/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`
        )
        .then((response) => dispatch(getReaderData(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchAccessUserData =
  (id_station, lid_orga) => async (dispatch) => {
    CheckConfigAxios();
    //console.log(`/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`);
    try {
      axiosInstance
        .get(`/organisation/${lid_orga}/station/${id_station}/useraccess`)
        .then((response) => dispatch(getAccessUserData(response.data)));
    } catch (e) {
      return console.error(e.message);
    }
  };

export const fetchBookingData = (id_station, lid_orga) => async (dispatch) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .get(`/organisation/${lid_orga}/station/${id_station}/userbooking`)
      .then((response) => dispatch(getBookingData(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const fetchUserDetails = (id_user) => async (dispatch) => {
  CheckConfigAxios();
  //console.log(`/organisation/${lid_orga}/station/${id_station}/access/${id_reader}/data`);
  try {
    axiosInstance
      .get(`/customer/${id_user}`)
      .then((response) => dispatch(getUserDetails(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .delete(`customer/${data.data_type.slice(9)}`)
      .then((response) => dispatch(removeUser(data.data_type)));
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateUser = (data) => async (dispatch, getState) => {
  CheckConfigAxios();
  try {
    axiosInstance
      .put(`customer/${data.data_type.slice(9)}`, data)
      .then((response) => dispatch(updateStateUser(response.data)));
  } catch (e) {
    return console.error(e.message);
  }
};
