import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import datasrevReducer from "./slices/datasrev";
import adminsrevReducer from "./slices/adminsrev";

export const store = configureStore(
  {
    reducer: {
      datasrev: datasrevReducer,
      adminsrev: adminsrevReducer,
    },
  },
  composeWithDevTools()
);
